import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import {
    AnnouncementModel,
    PagedListModel,
    SimpleItemModel,
} from '../models/all';
import { ApiService, StorageService } from './all';
import {
    AnnouncementsGetRequest,
    AnnouncementsInsertRequest,
    AnnouncementsUpdateRequest,
    AnnouncementsDeleteRequest,
    AnnouncementsPublicGetRequest,
    GetByIdRequest,
} from '../models/requests/all';

@Injectable({
    providedIn: 'root',
})
export class AnnouncementsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: AnnouncementsGetRequest) {
        return this.sendGetRequest<PagedListModel<AnnouncementModel>>(
            'announcements/filter',
            request
        );
    }

    getById(request: GetByIdRequest) {
        return this.sendGetRequest<AnnouncementModel>('announcements', request);
    }

    getPublic(request: AnnouncementsPublicGetRequest) {
        return this.sendGetRequest<PagedListModel<AnnouncementModel>>(
            'announcements/getpublicannouncements',
            request
        );
    }

    getSelectList() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'announcements/select-list'
        );
    }

    getAnnouncementsForEducator(request?: AnnouncementsGetRequest) {
        return this.sendGetRequest<PagedListModel<AnnouncementModel>>(
            'announcements/getannouncementsforeducator',
            request
        );
    }

    insert(request: AnnouncementsInsertRequest) {
        return this.sendPostRequest<AnnouncementModel>(
            'announcements',
            request
        );
    }

    update(request: AnnouncementsUpdateRequest) {
        return this.sendPutRequest('announcements', request);
    }

    delete(request: AnnouncementsDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('announcements', request);
    }

    getDetailed(id: number) {
        return this.sendGetRequest<AnnouncementModel>('announcements/get-detailed?id=' + id);
    }
}
