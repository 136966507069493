import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Select } from '@ngxs/store';
import { ActiveRoleState, AuthState } from 'app/stores/all';
import { SimpleItemModel } from 'app/models/simple-item.model';
import { UnSub } from 'app/shared/extensions/unSub';
import { StorageService } from 'app/services/storage.service';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import { UserModel } from 'app/models/user.model';
import { logoTextUrl } from 'app/constants/constants';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent extends UnSub implements OnInit, OnDestroy {
    logoTextUrl = logoTextUrl;
    isScreenSmall: boolean;
    navigation: Navigation;
    user: UserModel;
    navigationFixed: FuseNavigationItem[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Select(AuthState.details) details$: Observable<any>;
    @Select(ActiveRoleState.activeRole)
    activeRole$: Observable<SimpleItemModel>;
    isTreasurer: boolean = this._authService.isTreasurer();
    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _storageService: StorageService,
        public _authService: AuthService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((navigation: Navigation) => {
                this.navigationFixed = navigation.default;
                this.navigation = navigation;
            });

        // Subscribe to the user service
        // this._userService.user$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //     });

        this.details$.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
            this.user = x;
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.unsubscribe$.complete();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    isPreschoolAdmin(): boolean {
        return (
            this._storageService.getActiveRole() ==
            ActiveRoleEnum.PreschoolInstitutionAdministrator
        );
    }

    get institutionName() {
        return this.user.PreschoolInstitutions[0]?.label;
    }
}
