import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { Router } from '@angular/router';
import {
    GetChildGalleriesRequest,
    GetGalleryRequest,
} from 'app/models/gallery-photo.model';
import {
    GalleriesDeleteRequest,
    GalleriesInsertRequest,
    GalleriesUpdateRequest,
    GetPublicGalleriesRequest,
} from 'app/models/requests/galleries.request';
import {
    GalleryPhotoDeleteRequest,
    GalleryPhotoInsertMultipleRequest,
    GalleryPhotoInsertRequest,
    GalleryPhotoUpdateRequest,
} from 'app/models/requests/gallery-photos.request';
import { BehaviorSubject } from 'rxjs';
import { GalleryModel, GalleryPhotoModel, PagedListModel } from '../models/all';
import { ApiService, StorageService } from './all';

@Injectable({ providedIn: 'root' })
export class GalleriesService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService,
        private _router: Router
    ) {
        super(store, httpClient, storageService);
    }

    private gallery = new BehaviorSubject<GalleryModel>(null);
    gallery$ = this.gallery.asObservable();

    private files = new BehaviorSubject<GalleryPhotoModel[]>(null);
    files$ = this.files.asObservable();

    private photo = new BehaviorSubject<GalleryPhotoModel>(null);
    photo$ = this.photo.asObservable();

    subscribeGallery(gallery: GalleryModel) {
        this.gallery.next(gallery);
    }

    subscribeFiles(files: GalleryPhotoModel[]) {
        this.files.next(files);
    }

    subscribePhoto(photo: GalleryPhotoModel) {
        this.photo.next(photo);
    }

    unSubscribeGallery() {
        this.gallery.next(null);
    }

    unSubscribeFiles() {
        this.files.next([]);
    }

    unSubscribePhoto() {
        this.photo.next(null);
    }

    /**
     *
     * @param gallery pass gallery model and application will open that gallery directly in gallery page (padmin, manager, educator...)
     */
    openGalleryDirectly(gallery: GalleryModel) {
        this.subscribeGallery(gallery);
        this._router.navigate(['/gallery']);
    }

    /********************************** */
    /* API CALLS - GALLERIES CONTROLLER */

    getGalleries(request: GetGalleryRequest) {
        return this.sendGetRequest<PagedListModel<GalleryModel>>(
            'galleries/getgalleries',
            request
        );
    }

    getParentGallery(galleryId: number) {
        return this.sendGetRequest<any[]>(
            'parentgalleries/getgalleries?galleryId=' + galleryId
        );
    }

    getChildsGalleries(request: GetChildGalleriesRequest) {
        return this.sendGetRequest<PagedListModel<GalleryModel>>(
            'galleries/get-childs-galleries',
            request
        );
    }

    getGalleriesForEducator() {
        return this.sendGetRequest<GalleryModel[]>(
            'galleries/get-galleries-for-educator'
        );
    }

    getPublicGalleries(request: GetPublicGalleriesRequest) {
        return this.sendGetRequest<any>('galleries/getpublic', request);
    }

    getPhotos(galleryId: number) {
        return this.sendGetRequest<GalleryPhotoModel[]>(
            'galleries/get-photos?galleryId=' + galleryId
        );
    }

    getPublicPhotos(galleryId: number) {
        return this.sendGetRequest<GalleryPhotoModel[]>(
            'galleries/get-public-photos?galleryId=' + galleryId
        );
    }

    getParentPhotos(galleryId: number) {
        return this.sendGetRequest<GalleryPhotoModel[]>(
            'galleries/get-parent-photos?galleryId=' + galleryId
        );
    }

    isChildOrParentSpecificGallery(galleryId: number) {
        return this.sendGetRequest<boolean>(
            'galleries/is-child-or-parent-specific-gallery?galleryId=' +
                galleryId
        );
    }

    insert(request: GalleriesInsertRequest) {
        return this.sendPostRequest<GalleryModel>('galleries', request);
    }

    update(request: GalleriesUpdateRequest) {
        return this.sendPutRequest<GalleryModel>('galleries', request);
    }

    delete(request: GalleriesDeleteRequest) {
        return this.sendDeleteRequest('galleries', request);
    }

    getById(id: number) {
        return this.sendGetRequest<GalleryModel>('galleries?id=' + id);
    }

    /****************************************** */
    /* API CALLS - GALLERIES PHOTOS CONTROLLER */

    insertPhoto(request: GalleryPhotoInsertRequest) {
        return this.sendPostRequest<GalleryPhotoModel>(
            'galleryphotos',
            request
        );
    }

    insertMultiplePhotos(request: GalleryPhotoInsertMultipleRequest) {
        return this.sendPostRequest<GalleryPhotoModel[]>(
            'galleryphotos/upload-multiple',
            request
        );
    }

    getChildGallery(childId: number) {
        return this.sendGetRequest<GalleryModel>(
            'childgallery/get-child-gallery?childId=' + childId
        );
    }

    updatePhoto(request: GalleryPhotoUpdateRequest) {
        return this.sendPutRequest<GalleryPhotoModel>('galleryphotos', request);
    }

    deletePhoto(request: GalleryPhotoDeleteRequest) {
        return this.sendDeleteRequest('galleryphotos', request);
    }
}
