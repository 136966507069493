import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { ProgramModel, SimpleItemModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
    ProgramsGetRequest,
    ProgramsInsertRequest,
    ProgramsUpdateRequest,
    ProgramsDeleteRequest,
    ProgramsGetByBusinessUnitRequest,
} from '../models/requests/all';

@Injectable({ providedIn: 'root' })
export class ProgramsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request?: ProgramsGetRequest) {
        return this.sendGetRequest<ProgramModel[]>('programs/filter', request);
    }

    insert(request: ProgramsInsertRequest) {
        return this.sendPostRequest<ProgramModel>('programs', request);
    }

    update(request: ProgramsUpdateRequest) {
        return this.sendPutRequest('programs', request);
    }

    delete(request: ProgramsDeleteRequest): Observable<void> {
        return this.sendDeleteRequest('programs', request);
    }

    getProgramByBusinessUnit(request: ProgramsGetByBusinessUnitRequest) {
        return this.sendGetRequest<SimpleItemModel[]>(
            'programs/listofprogramsbybusinessunit',
            request
        );
    }
}
