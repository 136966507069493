export enum RoleEnum {
    SuperAdministrator = 1,
    MinistryRepresentative = 2,
    PreschoolInstitutionAdministrator = 3,
    PreschoolInstitutionManager = 4,
    BusinessUnitAdministrator = 5,
    BusinessUnitEmployee = 6,
    Parent = 7,
    Expert = 8,
    Educator = 9,
    Coordinator = 10,
    Treasurer = 11,
}

export enum ActiveRoleEnum {
    SuperAdministrator = 'superadministrator',
    MinistryRepresentative = 'ministryrepresentative',
    PreschoolInstitutionAdministrator = 'preschoolinstitutionadministrator',
    PreschoolInstitutionManager = 'preschoolinstitutionmanager',
    BusinessUnitAdministrator = 'businessunitadministrator',
    BusinessUnitEmployee = 'businessunitemployee',
    Parent = 'parent',
    Expert = 'expert',
    Educator = 'educator',
    Coordinator = 'coordinator',
    Treasurer = 'treasurer',
}
