import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SimpleItemModel } from '../models/all';
import { ApiService, StorageService } from './all';
import {
    EmployeeEnumSpecificGetRequest,
    RoleEnumSpecificGetRequest,
    WeekdayEnumSpecificGetRequest,
} from 'app/models/requests/enum.request';

@Injectable({
    providedIn: 'root',
})
export class EnumsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getGenders() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/genders');
    }

    getParentshipTypes() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/parentship-types');
    }

    getQualifications() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/qualifications');
    }

    getEnrollmentStatusTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-status-types'
        );
    }

    getPaymentMethods() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/payment-methods');
    }

    getRoles() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/roles');
    }

    getSpecificEmployeeTypes(request: EmployeeEnumSpecificGetRequest) {
        return this.sendPostRequest<SimpleItemModel[]>(
            'enums/specific-employee-types',
            request
        );
    }

    getSpecificRoles(request: RoleEnumSpecificGetRequest) {
        return this.sendPostRequest<SimpleItemModel[]>(
            'enums/specific-role-types',
            request
        );
    }

    getProgramTypes() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/program-types');
    }

    getChildrenDevelopmentalStatuses() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/child-developmental-statuses'
        );
    }

    getRehabilitationProcesses() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/rehabilitation-processes'
        );
    }

    getDifficultiesDiagnosticProcedures() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/difficulties-diagnostic-procedures'
        );
    }

    getVisibilities() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/visibilities');
    }

    getGalleryVisibilities() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/gallery-visibilities'
        );
    }

    getAnnouncements() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/announcements');
    }

    getRequestStatusTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/request-status-types'
        );
    }

    getPaymentStatusTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/payment-status-type'
        );
    }

    getLanguages() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/languages');
    }

    getMonths() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/months');
    }

    getPublicSearchType() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/public-search-type'
        );
    }

    getEnrollmentAttendanceTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-attendance-types'
        );
    }

    getDocumentsConfiguration() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/documents-configuration'
        );
    }

    getChildReturned() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/child-returned');
    }

    getEnrollmentAttendanceForEveryoneTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-attendance-for-everyone'
        );
    }

    getNewEnrollmentRequestTypesForParents() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/new-enrollment-request-types'
        );
    }

    getEnrollmentRenewRequestTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-renew-request-types'
        );
    }

    getDismissalTypes() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/dismissal-types');
    }

    getThematicSectionTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/thematic-section-types'
        );
    }

    getSpecificWeekdays(request: WeekdayEnumSpecificGetRequest) {
        return this.sendPostRequest<SimpleItemModel[]>(
            'enums/specific-weekdays',
            request
        );
    }

    getWeekdays() {
        return this.sendGetRequest<SimpleItemModel[]>('enums/weekdays');
    }

    getCollaborationTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/collaboration-types'
        );
    }

    getEnrollmentParentStatusTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-parent-status-types'
        );
    }

    getEnrollmentSpecificDocumentTypes() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'enums/enrollment-specific-document-types'
        );
    }
}
