import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { FuseCardModule } from '@fuse/components/card';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { PipesModule } from '@fuse/pipes/pipes.module';
import { MY_FORMATS } from 'app/constants/date-format';
import { ComponentsModule } from 'app/layout/components/components.module';
import { FormControlErrorModule } from 'app/shared/errors/form-control-error/form-control-error.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { DeletePromptModule } from './modals/delete-prompt-modal/delete-prompt-modal.module';
import { AddressModule } from './address/address/address.module';
import { NotFoundDataComponent } from './custom-components/not-found-data/not-found-data.component';
import { SnackbarModule } from './snackbar/snackbar.module';
import { HistoryModalComponent } from './custom-components/history-modal/history-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [
        PipesModule,
        FormControlErrorModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        DeletePromptModule,
        MaterialFileInputModule,
        FuseNavigationModule,
        ComponentsModule,
        AddressModule,
        SnackbarModule,
        MatIconModule,
        MatExpansionModule
    ],
    exports: [
        PipesModule,
        FormControlErrorModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        DeletePromptModule,
        MaterialFileInputModule,
        FuseNavigationModule,
        ComponentsModule,
        FuseCardModule,
        FuseCardModule,
        NotFoundDataComponent,
        HistoryModalComponent,
    ],
    providers: [provideMomentDateAdapter(MY_FORMATS, { useUtc: true })],
    declarations: [NotFoundDataComponent, HistoryModalComponent],
})
export class SharedModule {}
