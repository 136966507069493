import { NgModule } from '@angular/core';
import { AsterixPipe } from './asterix/asterix.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { DateFormatPipe, DateTimeFormatPipe, TimeFormatPipe } from './date-format/date-format.pipe';
import { FileFormatPipe } from './file-format/file-format.pipe';
import { FileSizeConverterPipe } from './files-length/files-length.pipe';
import { FuseFindByKeyPipe } from './find-by-key/find-by-key.pipe';
import { CleanHtmlPipe } from './html/clean-html.pipe';
import { RelativeDatePipe } from './relative-date/relative-date.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { CommonModule } from '@angular/common';
import { FileIconPipe } from './file-icon/file-icon.pipe';
import { ChildPhotoPipe } from './child-photo/child-photo.pipe';
import { GalleryIconPipe, IconPipe, VisibilityIconPipe } from './mat-icon/mat-icon.pipe';
import { UserPhotoPipe } from './user-photo/user-photo.pipe';
import { StatusClassPipe } from './status-class/status-class.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AsterixPipe,
        CapitalizePipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        TimeFormatPipe,
        FileFormatPipe,
        FileSizeConverterPipe,
        FuseFindByKeyPipe,
        CleanHtmlPipe,
        RelativeDatePipe,
        TruncatePipe,
        FileIconPipe,
        ChildPhotoPipe,
        IconPipe,
        VisibilityIconPipe,
        UserPhotoPipe,
        GalleryIconPipe,
        StatusClassPipe
    ],
    exports: [
        AsterixPipe,
        CapitalizePipe,
        DateFormatPipe,
        DateTimeFormatPipe,
        TimeFormatPipe,
        FileFormatPipe,
        FileSizeConverterPipe,
        FuseFindByKeyPipe,
        CleanHtmlPipe,
        RelativeDatePipe,
        TruncatePipe,
        FileIconPipe,
        ChildPhotoPipe,
        IconPipe,
        VisibilityIconPipe,
        UserPhotoPipe,
        GalleryIconPipe,
        StatusClassPipe
    ],
})
export class PipesModule {}
