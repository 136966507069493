import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    GetChildrenByParentRequest,
    ParentshipExtendRequest,
    ParentshipsByChildId,
    ParentshipsGetInfoRequest,
    RemoveParentChildParentshipRequest,
} from 'app/models/requests/parentships.request';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { ChildModel } from 'app/models/child.model';
import { PersonModel } from 'app/models/person.model';

@Injectable({
    providedIn: 'root',
})
export class ParentshipsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    info(request: ParentshipsGetInfoRequest) {
        return this.sendGetRequest<any>('parentships/child-info', request);
    }

    checkChildsParent(request: ParentshipsByChildId) {
        return this.sendGetRequest<any>(
            'parentships/check-childs-parent',
            request
        );
    }

    getAllChildrenByParent(request: GetChildrenByParentRequest) {
        return this.sendGetRequest<ChildModel[]>(
            'parentships/get-all-children-by-parent',
            request
        );
    }

    removeParentChildParentship(request: RemoveParentChildParentshipRequest) {
        return this.sendDeleteRequest(
            'parentships/remove-parentship-child',
            request
        );
    }

    getChildParentships(childId: number) {
        return this.sendGetRequest<PersonModel[]>(
            'parentships/get-child-parents?childId=' + childId
        );
    }

    extendParentship(request: ParentshipExtendRequest) {
        return this.sendPutRequest('parentships/extend-parentship', request);
    }
}
